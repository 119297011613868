import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path:'calificar',
    loadChildren: ()=> import('./Infraestructure/pages/nps-puntos/nps-puntos.module').then(m => m.NpsPuntosModule)
  },
  {
    path:'coins',
    loadChildren: ()=> import('./Infraestructure/pages/nps-coins/nps-coins.module').then(m => m.NpsCoinsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
